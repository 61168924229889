import { createEffect, createEvent } from 'effector';

import { apiFetch } from 'utils/http';

import type { RateBaseV2, ValidationBase, Visit, UserVisit } from 'types/api';

/**
 * Customer fetch visits
 */
export const setCustomerVisits = createEvent<Visit[]>();
export const fetchCustomerVisits = async () => {
  const response = await apiFetch<{ transactions: Visit[] }>('/customer/visits');
  if (response.success) setCustomerVisits(response.data!.transactions);
  return response;
};

/**
 * Customer requests vehicle. Used in the valet flow
 */
export const customerRequestVehicle = createEffect({
  handler: async ({ visitId }: { visitId: number }) => {
    const response = await apiFetch(`/customer/visit/${visitId}/request-car`, { method: 'POST' });
    return response;
  },
});

/**
 * Set visit tip before paying
 * @param visitId
 * @param tip - Dollar formatted, e.g. $7.50 == 7.5
 */
export const customerSetTip = createEffect({
  handler: async ({ visitId, tip }: { visitId: number; tip: number }) =>
    apiFetch(`/customer/visit/${visitId}/set-tip`, {
      method: 'POST',
      // @ts-ignore ts-migrate(2322) FIXME: Type '{ tip: number; }' is not assignable to ty
      body: { tip },
    }),
});

/**
 * Get tip settings for the visit
 */
export const fetchTipSettings = createEffect({
  handler: async ({ visitId }: { visitId: number }) =>
    apiFetch(`/customer/visit/${visitId}/tip-settings`, {
      method: 'GET',
      // @ts-ignore ts-migrate(2322) FIXME: Type '{ visitId: number; }' is not assignable to t.
      params: { visitId },
    }),
});

/**
 * Pay for visit. Used by customer
 */
export const customerVisitPayment = createEffect({
  handler: async ({ paymentMethodId, visitId }: { visitId: number; paymentMethodId?: number }) => {
    let params;
    if (paymentMethodId) params = { paymentMethodId };

    const response = await apiFetch(`/customer/visit/${visitId}/pay`, {
      method: 'POST',
      // @ts-ignore ts-migrate(2322) FIXME: Type '{ paymentMethodId: number; }' is not assigna
      params,
    });

    if (response.success) {
      // TODO: remove this once the visit is returned in the response
      await fetchCustomerVisits();
    }
    return response;
  },
});

/**
 * Validation with QR scanner
 */
export const customerApplyValidation = createEffect({
  handler: async ({ validationUUID }: { validationUUID: string }) =>
    apiFetch(`/customer/validation/${validationUUID}`, { method: 'POST' }),
});

/**
 * Fetch extend visit options for scan to pay sites
 */
export const fetchExtendVisitOptions = ({ visitId }: { visitId: number }) =>
  apiFetch(`/customer/visit/${visitId}/extend-options`);

/**
 * Extend a visits duration for scan to pay sites
 */
export const extendVisitDuration = async ({
  visitId,
  minutesToAdd,
}: {
  visitId: number;
  minutesToAdd: number;
}) => {
  const response = await apiFetch(`/customer/visit/${visitId}/extend`, {
    method: 'POST',
    body: {
      minutesToAdd,
    },
  });

  if (response.success) {
    await fetchCustomerVisits();
  }

  return response;
};

/**
 * Check if a site has active validations
 */

export const fetchSiteHasValidation = ({ siteId }: { siteId: number }) =>
  apiFetch(`/site/${siteId}/is-validation-available`);

/**
 * Sets the validation that will be applied
 * Used when a customer attempts to apply a validation using their device's camera app
 */
export const setQRValidation = createEvent<string | null>();

/**
 * Sets the current visit user session object onto store
 * Used for view layers in DIDO start parking
 */
export const setCurrentVisitUserSession = createEvent<{
  validation: ValidationBase;
  rate: RateBaseV2;
}>();

/**
 * We will use this to track if the pending validation is the applied validation
 * this is when the user goes from onboarding page to visit page
 */
export const setUserPendingValidation = createEvent<{
  id: number;
  validationUuid?: string;
} | null>();

export const resetCustomerVisitStore = createEvent<void>();

export const userConfirmedVisit = createEvent<boolean>();

export const updatedVisitHistory = createEvent<UserVisit[]>();

export const setDoorOpenRequest = createEvent<string | null>();
