import { createStore, Event } from 'effector';

import { applyReducers } from '@metropolis-io/effector-utils';

import { logout } from 'apps/customer/state/user';

import {
  showSideMenu,
  hideSideMenu,
  setSideMenuActiveItem,
  toggleSideMenu,
  showLastVisitModal,
  hideLastVisitModal,
  showConfirmationModal,
  hideConfirmationModal,
  showHelpModal,
  hideHelpModal,
  showAppModal,
  hideAppModal,
  showAgreementSheet,
  hideAgreementSheet,
  setIsValetPaymentScreenOpen,
  setIsValetCancelRequestScreenOpen,
} from './actions';

import { PARKING_DETAIL, AllMenuItemNames } from 'constants/CustomerAppSideMenuItemNames';

type State = {
  isSideMenuVisible: boolean;
  sideMenuActiveItem: AllMenuItemNames;
  isConfirmationModalVisible: boolean;
  isLastVisitModalVisible: boolean;
  isHelpModalVisible: boolean;
  isAppModalVisible: boolean;
  appModalContent: JSX.Element | null;
  appModalCloseTimeout: number | null;
  appModalStyleOverrides: string | null;
  isAgreementSheetVisible: boolean;
  isValetPaymentScreenOpen: boolean;
  isValetCancelRequestScreenOpen: boolean;
};

const initialState: State = {
  isSideMenuVisible: false,
  sideMenuActiveItem: PARKING_DETAIL,
  isConfirmationModalVisible: false,
  isLastVisitModalVisible: false,
  isHelpModalVisible: false,
  isAppModalVisible: false,
  appModalContent: null,
  appModalCloseTimeout: null,
  appModalStyleOverrides: null,
  isAgreementSheetVisible: false,
  isValetPaymentScreenOpen: false,
  isValetCancelRequestScreenOpen: false,
};
const store = createStore(initialState);

type Reducers = {
  [actionName: string]: {
    action:
      | Event<void>
      | Event<boolean>
      | Event<{ itemName: AllMenuItemNames }>
      | Event<{ content: JSX.Element; timeout?: number }>
      | Event<Parameters<typeof hideAppModal>[0]>;
    reducer: (
      state: State,
      payload: Parameters<typeof showAppModal>[0] & { itemName: AllMenuItemNames } & boolean,
    ) => State;
  };
};

export const reducers: Reducers = {
  showSideMenu: {
    action: showSideMenu,
    reducer: (state) => ({
      ...state,
      isSideMenuVisible: true,
    }),
  },
  hideSideMenu: {
    action: hideSideMenu,
    reducer: (state) => ({
      ...state,
      isSideMenuVisible: false,
    }),
  },
  setSideMenuActiveItem: {
    action: setSideMenuActiveItem,
    reducer: (state, { itemName }) => ({
      ...state,
      sideMenuActiveItem: itemName,
    }),
  },
  toggleSideMenu: {
    action: toggleSideMenu,
    reducer: (state) => ({
      ...state,
      isSideMenuVisible: !state.isSideMenuVisible,
    }),
  },
  showLastVisitModal: {
    action: showLastVisitModal,
    reducer: (state) => ({
      ...state,
      isLastVisitModalVisible: true,
    }),
  },
  hideLastVisitModal: {
    action: hideLastVisitModal,
    reducer: (state) => ({
      ...state,
      isLastVisitModalVisible: false,
    }),
  },
  showConfirmationModal: {
    action: showConfirmationModal,
    reducer: (state) => ({
      ...state,
      isConfirmationModalVisible: true,
    }),
  },
  hideConfirmationModal: {
    action: hideConfirmationModal,
    reducer: (state) => ({
      ...state,
      isConfirmationModalVisible: false,
    }),
  },
  showHelpModal: {
    action: showHelpModal,
    reducer: (state) => ({
      ...state,
      isHelpModalVisible: true,
    }),
  },
  hideHelpModal: {
    action: hideHelpModal,
    reducer: (state) => ({
      ...state,
      isHelpModalVisible: false,
    }),
  },
  showAppModal: {
    action: showAppModal,
    reducer: (state, { content, timeout, styleOverrides }) => ({
      ...state,
      appModalContent: content,
      isAppModalVisible: true,
      appModalCloseTimeout: timeout || null,
      appModalStyleOverrides: styleOverrides || null,
    }),
  },
  hideAppModal: {
    action: hideAppModal,
    reducer: (state) => ({
      ...state,
      // Don't reset appModalContent or appModalCloseTimeout
      isAppModalVisible: false,
      appModalStyleOverrides: null,
    }),
  },
  showAgreementSheet: {
    action: showAgreementSheet,
    reducer: (state) => ({
      ...state,
      isAgreementSheetVisible: true,
    }),
  },
  hideAgreementSheet: {
    action: hideAgreementSheet,
    reducer: (state) => ({
      ...state,
      isAgreementSheetVisible: false,
    }),
  },
  setIsValetPaymentScreenOpen: {
    action: setIsValetPaymentScreenOpen,
    reducer: (state, isValetPaymentScreenOpen) => ({
      ...state,
      isValetPaymentScreenOpen,
    }),
  },
  setIsValetCancelRequestScreenOpen: {
    action: setIsValetCancelRequestScreenOpen,
    reducer: (state, isValetCancelRequestScreenOpen) => ({
      ...state,
      isValetCancelRequestScreenOpen,
    }),
  },
};

store.reset(logout.done);

export default applyReducers({ store, reducers });
