/**
 * Use this store for anything Onboarding related
 */

import { createStore, Event } from 'effector';

import { applyReducers } from '@metropolis-io/effector-utils';

import {
  setLastVehicleWasPreviouslyAdded,
  setLpSuggestionWasGiven,
  setLpSuggestionWasSelected,
  setLpCorrectionWasAskedToConfirm,
  setLpCorrectionUserCorrectedLp,
  setLpCorrectionTransactionId,
  setLpCorrectionHasBeenReported,
  setLpCorrectionShouldTrack,
  resetLpCorrectionMetricState,
  setClickedLinkOnConfirmPage,
} from './actions';

type OnboardState = {
  /** Sets whether or not the last vehicle added via addVehicle() was previously added by another user */
  lastVehicleWasPreviouslyAdded: boolean | null; // useful when transitioning from onboarding to visit page
  lpCorrectionMetrics: {
    suggestionWasGiven: boolean;
    suggestionWasSelected: boolean;
    viewedConfirmationScreen: boolean;
    confirmedWithOurPlateRead: boolean;
    transactionId: number | null;
    hasBeenReported: boolean;
    shouldTrack: boolean;
    clickedLinkOnConfirmPage: boolean;
  };
};

const lpCorrectionMetricsDefaultState = {
  suggestionWasGiven: false,
  suggestionWasSelected: false,
  viewedConfirmationScreen: false,
  confirmedWithOurPlateRead: false,
  transactionId: null,
  hasBeenReported: false,
  shouldTrack: false,
  clickedLinkOnConfirmPage: false,
};

const initalState: OnboardState = {
  lastVehicleWasPreviouslyAdded: null,
  lpCorrectionMetrics: lpCorrectionMetricsDefaultState,
};

const store = createStore(initalState);

type Reducers = {
  setLastVehicleWasPreviouslyAdded: {
    action: Event<boolean | null>;
    reducer: (state: OnboardState, payload: boolean | null) => OnboardState;
  };
  setLpSuggestionWasGiven: {
    action: Event<boolean>;
    reducer: (state: OnboardState, payload: boolean) => OnboardState;
  };
  setLpSuggestionWasSelected: {
    action: Event<boolean>;
    reducer: (state: OnboardState, payload: boolean) => OnboardState;
  };
  setLpCorrectionWasAskedToConfirm: {
    action: Event<boolean>;
    reducer: (state: OnboardState, payload: boolean) => OnboardState;
  };
  setLpCorrectionUserCorrectedLp: {
    action: Event<boolean>;
    reducer: (state: OnboardState, payload: boolean) => OnboardState;
  };
  setLpCorrectionTransactionId: {
    action: Event<number | null>;
    reducer: (state: OnboardState, payload: number | null) => OnboardState;
  };
  setLpCorrectionHasBeenReported: {
    action: Event<boolean>;
    reducer: (state: OnboardState, payload: boolean) => OnboardState;
  };
  setLpCorrectionShouldTrack: {
    action: Event<boolean>;
    reducer: (state: OnboardState, payload: boolean) => OnboardState;
  };
  resetLpCorrectionMetricState: {
    action: Event<boolean>;
    reducer: (state: OnboardState, payload: boolean) => OnboardState;
  };
  setClickedLinkOnConfirmPage: {
    action: Event<boolean>;
    reducer: (state: OnboardState, payload: boolean) => OnboardState;
  };
};

export const reducers: Reducers = {
  setLastVehicleWasPreviouslyAdded: {
    action: setLastVehicleWasPreviouslyAdded,
    reducer: (state, payload) => ({
      ...state,
      lastVehicleWasPreviouslyAdded: payload,
    }),
  },
  setLpSuggestionWasGiven: {
    action: setLpSuggestionWasGiven,
    reducer: (state, payload) => ({
      ...state,
      lpCorrectionMetrics: {
        ...state.lpCorrectionMetrics,
        suggestionWasGiven: payload,
      },
    }),
  },
  setLpSuggestionWasSelected: {
    action: setLpSuggestionWasSelected,
    reducer: (state, payload) => ({
      ...state,
      lpCorrectionMetrics: {
        ...state.lpCorrectionMetrics,
        suggestionWasSelected: payload,
      },
    }),
  },
  setLpCorrectionWasAskedToConfirm: {
    action: setLpCorrectionWasAskedToConfirm,
    reducer: (state, payload) => ({
      ...state,
      lpCorrectionMetrics: {
        ...state.lpCorrectionMetrics,
        viewedConfirmationScreen: payload,
      },
    }),
  },
  setLpCorrectionUserCorrectedLp: {
    action: setLpCorrectionUserCorrectedLp,
    reducer: (state, payload) => ({
      ...state,
      lpCorrectionMetrics: {
        ...state.lpCorrectionMetrics,
        confirmedWithOurPlateRead: payload,
      },
    }),
  },
  setLpCorrectionTransactionId: {
    action: setLpCorrectionTransactionId,
    reducer: (state, payload) => ({
      ...state,
      lpCorrectionMetrics: {
        ...state.lpCorrectionMetrics,
        transactionId: payload,
      },
    }),
  },
  setLpCorrectionHasBeenReported: {
    action: setLpCorrectionHasBeenReported,
    reducer: (state, payload) => ({
      ...state,
      lpCorrectionMetrics: {
        ...state.lpCorrectionMetrics,
        hasBeenReported: payload,
      },
    }),
  },
  setLpCorrectionShouldTrack: {
    action: setLpCorrectionShouldTrack,
    reducer: (state, payload) => ({
      ...state,
      lpCorrectionMetrics: {
        ...state.lpCorrectionMetrics,
        shouldTrack: payload,
      },
    }),
  },
  resetLpCorrectionMetricState: {
    action: resetLpCorrectionMetricState,
    reducer: (state, payload) => ({
      ...state,
      lpCorrectionMetrics: payload ? lpCorrectionMetricsDefaultState : state.lpCorrectionMetrics,
    }),
  },
  setClickedLinkOnConfirmPage: {
    action: setClickedLinkOnConfirmPage,
    reducer: (state, payload) => ({
      ...state,
      lpCorrectionMetrics: {
        ...state.lpCorrectionMetrics,
        clickedLinkOnConfirmPage: payload,
      },
    }),
  },
};

export default applyReducers({ store, reducers });
