import type { IdNameObject } from 'types';

import { PARKED_ID, PICKED_UP_ID } from 'constants/ParkedVehicleStatus';
import {
  PAYMENT_COMPLETED_ID,
  PAYMENT_DUE_ID,
  PAYMENT_FOREGONE_ID,
  PAYMENT_NOT_REQUIRED_ID,
} from 'constants/TransactionPaymentStatus';
import {
  CHECKED_IN_ID,
  READY_FOR_PICKUP_ID,
  RETRIEVAL_IN_PROGRESS_ID,
  RETRIEVAL_REQUESTED_ID,
  UNKNOWN_ID,
} from 'constants/ValetVehicleStatus';
import { SELF_PARK_SIGN_UP, VALET_CHECK_IN } from 'constants/VisitActions';

import type { Visit } from 'types/api';

type StatusType = IdNameObject | null;

export const valetVehicleStatusToID = {
  UNKNOWN: UNKNOWN_ID,
  CHECKED_IN: CHECKED_IN_ID,
  RETRIEVAL_REQUESTED: RETRIEVAL_REQUESTED_ID,
  RETRIEVAL_IN_PROGRESS: RETRIEVAL_IN_PROGRESS_ID,
  READY_FOR_PICKUP: READY_FOR_PICKUP_ID,
};

export const parkedStatusIdToName = {
  null: 'Ready for Check-In',
  1: 'Checked-In',
  2: 'Vehicle Requested',
  3: 'Retrieval In Progress',
  4: 'Ready for Pickup',
};

export const vehicleParkedState = ({ parkedVehicleStatus }: { parkedVehicleStatus: StatusType }) =>
  !!parkedVehicleStatus && parkedVehicleStatus.id === PARKED_ID;

export const vehiclePickedUpState = ({
  parkedVehicleStatus,
}: {
  parkedVehicleStatus: StatusType;
}) => !!parkedVehicleStatus && parkedVehicleStatus.id === PICKED_UP_ID;

export const vehicleRetrievalRequested = ({
  valetVehicleStatus,
}: {
  valetVehicleStatus: StatusType;
}) => !!valetVehicleStatus && valetVehicleStatus.id === RETRIEVAL_REQUESTED_ID;

// The below are states shown to customers on the CustomerApp
export const statusPrecedence = [
  RETRIEVAL_REQUESTED_ID,
  RETRIEVAL_IN_PROGRESS_ID,
  READY_FOR_PICKUP_ID,
  CHECKED_IN_ID,
  UNKNOWN_ID,
];

export const vehicleReadyForCheckin = ({
  valetVehicleStatus,
}: {
  valetVehicleStatus: StatusType;
}) => valetVehicleStatus === null;

export const vehicleCheckedIn = ({ valetVehicleStatus }: { valetVehicleStatus: StatusType }) =>
  !!valetVehicleStatus && valetVehicleStatus.id === CHECKED_IN_ID;

export const vehicleRetrievalInProgress = ({
  valetVehicleStatus,
}: {
  valetVehicleStatus: StatusType;
}) => !!valetVehicleStatus && valetVehicleStatus.id === RETRIEVAL_IN_PROGRESS_ID;

export const vehicleReadyForPickup = ({ valetVehicleStatus }: { valetVehicleStatus: StatusType }) =>
  !!valetVehicleStatus && valetVehicleStatus.id === READY_FOR_PICKUP_ID;

export const vehicleRequestedOrInProgress = ({
  valetVehicleStatus,
}: {
  valetVehicleStatus: StatusType;
}) => {
  const statusId = valetVehicleStatus && valetVehicleStatus.id;
  return statusId === RETRIEVAL_REQUESTED_ID || statusId === RETRIEVAL_IN_PROGRESS_ID;
};

export const visitPaymentForgone = ({
  transactionPaymentStatus,
}: {
  transactionPaymentStatus: StatusType;
}) => !!transactionPaymentStatus && transactionPaymentStatus.id === PAYMENT_FOREGONE_ID;

export const visitPaymentDue = ({
  transactionPaymentStatus,
}: {
  transactionPaymentStatus: StatusType;
}) => !!transactionPaymentStatus && transactionPaymentStatus.id === PAYMENT_DUE_ID;

export const visitPaymentNotRequired = ({
  transactionPaymentStatus,
}: {
  transactionPaymentStatus: StatusType;
}) => !!transactionPaymentStatus && transactionPaymentStatus.id === PAYMENT_NOT_REQUIRED_ID;

export const visitPaymentRequired = ({
  transactionPaymentStatus,
}: {
  transactionPaymentStatus: StatusType;
}) =>
  !!transactionPaymentStatus &&
  transactionPaymentStatus.id !== PAYMENT_COMPLETED_ID &&
  transactionPaymentStatus.id !== PAYMENT_NOT_REQUIRED_ID &&
  transactionPaymentStatus.id !== PAYMENT_FOREGONE_ID;

export const valetVisitOpen = ({
  parkedVehicleStatus,
  transactionPaymentStatus,
  valetVehicleStatus,
}: {
  parkedVehicleStatus: StatusType;
  transactionPaymentStatus: StatusType;
  valetVehicleStatus: StatusType;
}) =>
  (vehicleParkedState({ parkedVehicleStatus }) ||
    visitPaymentRequired({ transactionPaymentStatus })) &&
  !!valetVehicleStatus;

export const selfParkVisitOpen = ({
  parkedVehicleStatus,
  transactionPaymentStatus,
  valetVehicleStatus,
  selfParkVehicleStatus,
}: {
  parkedVehicleStatus: StatusType;
  transactionPaymentStatus: StatusType;
  valetVehicleStatus: StatusType;
  selfParkVehicleStatus: StatusType;
}) =>
  (vehicleParkedState({ parkedVehicleStatus }) ||
    visitPaymentRequired({ transactionPaymentStatus })) &&
  !valetVehicleStatus &&
  selfParkVehicleStatus !== null;

export const getVisitStatusDisplay = ({
  parkedVehicleStatus,
  transactionPaymentStatus,
  valetVehicleStatus,
  selfParkVehicleStatus,
  availableActionsForSpecialist: availableActions = [],
  uberStatusDisplay,
}: Visit) => {
  // TODO: remove `uberStatusDisplay` check after uber API feature branch
  // is merged back to API main branch
  if (uberStatusDisplay && uberStatusDisplay.visit !== null) {
    return 'Uber Park';
  }
  if (
    vehicleParkedState({ parkedVehicleStatus }) &&
    vehicleReadyForCheckin({ valetVehicleStatus })
  ) {
    if (availableActions.indexOf(SELF_PARK_SIGN_UP) > -1) {
      return 'New Visitor';
    }
    if (availableActions.indexOf(VALET_CHECK_IN) > -1) {
      return 'Ready for Check-In';
    }
    // Self park - Sent invitation already
    return null;
  }
  if (selfParkVehicleStatus) {
    if (visitPaymentRequired({ transactionPaymentStatus })) {
      return 'Payment Due';
    }
  }
  if (!valetVisitOpen({ parkedVehicleStatus, transactionPaymentStatus, valetVehicleStatus })) {
    if (visitPaymentForgone({ transactionPaymentStatus })) {
      return 'Payment Foregone';
    }
    return null;
  }
  if (vehicleCheckedIn({ valetVehicleStatus })) {
    return 'Checked-In';
  }
  if (vehicleRetrievalRequested({ valetVehicleStatus })) {
    return 'Requested';
  }
  if (vehicleRetrievalInProgress({ valetVehicleStatus })) {
    return 'Retrieval In Progress';
  }
  if (vehicleReadyForPickup({ valetVehicleStatus })) {
    if (visitPaymentRequired({ transactionPaymentStatus })) {
      return 'Retrieval Complete';
    }
    return 'Ready for Pickup';
  }
  return 'Unknown Status';
};
