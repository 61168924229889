import {
  VIOLATION_ACCEPT,
  VIOLATION_CLOSE,
  VIOLATION_DECLINE,
  VIOLATION_DISPUTE,
} from 'apps/admin/constants/ViolationActions';
import { SITE_API_BASE_URL } from 'config/env';
import { apiFetch, getAuthToken, getDefaultAuthMethod } from 'utils/http';

import type { Option } from 'types';

export const ViolationService = {
  fetchViolations: (
    params: {
      licensePlate?: string;
      extViolationId?: string;
      startDate?: string; // YYYY-MM-DD
      endDate?: string; // YYYY-MM-DD
      siteIds?: number[]; // remove this for the first phase
      operator?: number;
      violationStatus?: number;
      pageSize?: number;
      page?: number;
    } = {},
  ) => apiFetch('/violation/admin/violations', { params }),

  getViolationCount: (
    params: {
      licensePlate?: string;
      extViolationId?: string;
      startDate?: string; // YYYY-MM-DD
      endDate?: string; // YYYY-MM-DD
      siteIds?: number[];
      operator?: number;
      violationStatus?: number;
    } = {},
  ) => apiFetch('/violation/admin/violations/count', { params }),

  fetchViolationDetails: ({ violationId }: { violationId: number }) =>
    apiFetch(`/violation/${violationId}`),

  adjustFine: ({ violationId, fineAmount }: { violationId: number; fineAmount: number }) =>
    apiFetch(`/v1/admin/violations/${violationId}/adjust-fine`, {
      method: 'PUT',
      body: {
        fineAmount,
      },
    }),

  updateLicensePlate: ({
    violationId,
    plateText,
    plateState,
  }: {
    violationId: number;
    plateText: string;
    plateState: Option;
  }) =>
    apiFetch(`/violation/${violationId}/admin/license-plate-correction`, {
      method: 'PUT',
      body: {
        text: plateText,
        state: plateState,
      },
    }),

  closeViolation: ({ violationId, reasonId }: { violationId: number; reasonId: number }) =>
    apiFetch(`/violation/${violationId}/admin/close`, {
      method: 'PUT',
      body: {
        reasonId,
        action: VIOLATION_CLOSE,
      },
    }),

  disputeViolation: ({ violationId }: { violationId: number }) =>
    apiFetch(`/violation/${violationId}/admin/action`, {
      method: 'PUT',
      body: VIOLATION_DISPUTE,
    }),

  acceptDispute: ({ violationId, reasonId }: { violationId: number; reasonId: number }) =>
    apiFetch(`/violation/${violationId}/admin/close`, {
      method: 'PUT',
      body: {
        reasonId,
        action: VIOLATION_ACCEPT,
      },
    }),

  rejectDispute: ({ violationId }: { violationId: number }) =>
    apiFetch(`/violation/${violationId}/admin/action`, {
      method: 'PUT',
      body: VIOLATION_DECLINE,
    }),

  skiptraceReportExport: (body: {
    startDate: number; // millis
    endDate: number; // millis
    operatorId?: number;
    siteIds?: number[];
  }) =>
    apiFetch('/violation/admin/violations/skiptrace/export', {
      method: 'POST',
      body,
    }),

  /**
   * apiFetch uses workerize internally. service workers might not support form data?
   * @param file
   */
  importSkiptraceReport: async ({ file }: { file: File }) => {
    const formData = new FormData();
    formData.append('violations', file);

    const headers: HeadersInit = {};
    const authMethod = getDefaultAuthMethod();
    if (authMethod === 'token') {
      const authToken = await getAuthToken();
      if (authToken) {
        headers.Authorization = authToken;
      }
    }

    return fetch(`${SITE_API_BASE_URL}/violation/admin/violations/skiptrace/import`, {
      credentials: authMethod === 'cookie' ? 'include' : undefined,
      method: 'PUT',
      headers,
      body: formData,
    }).then((res) => res.json());
  },

  mailMergeExport: ({ operatorId }: { operatorId?: number }) =>
    apiFetch('/violation/admin/violations/mail/export', {
      method: 'POST',
      body: {
        operatorId,
      },
    }),

  fetchExportHistory: () => apiFetch('/violation/admin/violations/mail/export/history'),

  fetchViolationStatuses: () => apiFetch('/violation/statuses'),

  fetchViolationCloseReasons: () => apiFetch('/violation/close-reasons'),
};
