import { createStore, Effect, Event } from 'effector';
import isEqual from 'react-fast-compare';

import { applyReducers } from '@metropolis-io/effector-utils';
import type { DoneHandler } from '@metropolis-io/effector-utils';

import { logout } from 'apps/customer/state/user/actions';

import {
  customerApplyValidation,
  customerRequestVehicle,
  customerSetTip,
  customerVisitPayment,
  fetchTipSettings,
  resetCustomerVisitStore,
  setCustomerVisits,
  setQRValidation,
  setCurrentVisitUserSession,
  setUserPendingValidation,
  userConfirmedVisit,
  setDoorOpenRequest,
} from './actions';

import type { APIResponse } from 'utils/http';

import { PAYMENT_PROCESSING_FAILURE_ID } from 'constants/ResponseErrorCode';

import type { PastVisit, RateBaseV2, ValidationBase, Visit } from 'types/api';

type CustomerVisitState = {
  visits: Visit[];
  visitHistory: PastVisit[];
  unpaidVisits: PastVisit[];
  mostRecentVisit: Visit | null;
  initLoad: boolean;
  isMostRecentVisitOpen: boolean | null;
  requestVehicleError?: string;
  paymentError?: {
    /**
     *  Custom message from BE.
     *  Currently used to determine if we need to route to payment page to re-add payment.
     */
    isCustomMessage: boolean;
    message: string;
  };
  qrValidation?: string | null;
  currentVisitUserSession?: { validation: ValidationBase; rate: RateBaseV2 };
  tippingEnabled?: boolean | null;
  suggestedTipAmounts: number[];
  userHadToConfirmVisit?: boolean | null;
  userPendingValidation?: { id: number; validationUuid?: string } | null;
  doorOpenRequest: string | null;
};

const initialState: CustomerVisitState = {
  visits: [],
  visitHistory: [],
  unpaidVisits: [],
  mostRecentVisit: null,
  // Do not rely on async store since async store can be reset
  initLoad: false, // Ensures that there was an attempt to load a visit
  isMostRecentVisitOpen: null,
  suggestedTipAmounts: [],
  doorOpenRequest: null,
};

const store = createStore(initialState);

type Reducers = {
  setCustomerVisits: {
    action: Event<Visit[]>;
    reducer: (state: CustomerVisitState, payload: Visit[]) => CustomerVisitState;
  };
  customerRequestVehicle: {
    action: Effect<Parameters<typeof customerRequestVehicle>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof customerRequestVehicle>[0], CustomerVisitState>;
  };
  customerVisitPayment: {
    action: Effect<Parameters<typeof customerVisitPayment>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof customerVisitPayment>[0], CustomerVisitState>;
  };
  customerApplyValidation: {
    action: Effect<Parameters<typeof customerApplyValidation>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof customerApplyValidation>[0], CustomerVisitState>;
  };
  setQRValidation: {
    action: Event<string | null>;
    reducer: (state: CustomerVisitState, payload: string | null) => CustomerVisitState;
  };
  setCurrentVisitUserSession: {
    action: Event<{ validation: ValidationBase; rate: RateBaseV2 }>;
    reducer: (
      state: CustomerVisitState,
      payload: { validation: ValidationBase; rate: RateBaseV2 },
    ) => CustomerVisitState;
  };
  customerSetTip: {
    action: Effect<Parameters<typeof customerSetTip>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof customerSetTip>[0], CustomerVisitState>;
  };
  fetchTipSettings: {
    action: Effect<Parameters<typeof fetchTipSettings>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof fetchTipSettings>[0], CustomerVisitState>;
  };
  resetCustomerVisitStore: {
    action: Event<Parameters<typeof resetCustomerVisitStore>[0]>;
    skipAsyncStore: boolean;
  };
  userConfirmedVisit: {
    action: Event<boolean>;
    reducer: (state: CustomerVisitState, payload: boolean) => CustomerVisitState;
  };
  setDoorOpenRequest: {
    action: Event<string | null>;
    reducer: (state: CustomerVisitState, payload: string | null) => CustomerVisitState;
  };
  setUserPendingValidation: {
    action: Event<{ id: number; validationUuid?: string } | null>;
    reducer: (
      state: CustomerVisitState,
      payload: { id: number; validationUuid?: string } | null,
    ) => CustomerVisitState;
  };
};

export const reducers: Reducers = {
  setCustomerVisits: {
    action: setCustomerVisits,
    reducer: (state, visits) => {
      const isVisitsEqual = isEqual(visits, state.visits);
      const nextVisits = isVisitsEqual ? state.visits : visits;
      if (state.initLoad && isVisitsEqual) {
        return state;
      }
      return {
        ...state,
        visits: nextVisits,
        mostRecentVisit: nextVisits[0] || null,
        isMostRecentVisitOpen: visits[0]?.open || false,
        initLoad: true,
      };
    },
  },
  customerRequestVehicle: {
    action: customerRequestVehicle,
    done: (state, { result: { success, data } }) => {
      if (success) {
        const nextVisits = [...state.visits];
        const visitIndex = nextVisits.findIndex((visit) => visit.id === data.transaction.id);
        if (visitIndex > -1) {
          // TODO: Ensure the backend returns site and validation data in this route
          // otherwise the visit page blinks due to missing site and validation
          // ideally same response as fetchCustomerVisits
          // nextVisits[visitIndex] = data.transaction;
        }
        return {
          ...state,
          visits: nextVisits,
          mostRecentVisit: nextVisits[0] || null,
          requestVehicleError: undefined,
        };
      }
      return {
        ...state,
        // TODO: Handle this with the fail callback
        requestVehicleError: 'Unable to request vehicle. Please contact an attendant.',
      };
    },
  },
  // TODO: get visit in the response
  customerVisitPayment: {
    action: customerVisitPayment,
    done: (state, { result: { success, code, error } }) => {
      let paymentError;

      if (!success) {
        if (code && code.id === PAYMENT_PROCESSING_FAILURE_ID && error) {
          paymentError = {
            isCustomMessage: true,
            message: error,
          };
        } else {
          paymentError = {
            isCustomMessage: false,
            message: 'Payment failed. Please see an attendant.',
          };
        }
      }

      return {
        ...state,
        // TODO: Handle this with the fail callback
        paymentError,
      };
    },
  },
  customerApplyValidation: {
    action: customerApplyValidation,
    done: (state, { result: { success, data } }) => {
      if (success) {
        const { transaction, validation } = data;
        const nextVisits = [...state.visits];
        const visitIndex = nextVisits.findIndex((visit) => visit.id === transaction.id);
        if (visitIndex > -1) {
          // Update visit. Response only passes a partial transaction
          nextVisits[visitIndex] = {
            ...nextVisits[visitIndex],
            ...transaction,
            validation,
          };
        }
        return {
          ...state,
          visits: nextVisits,
          mostRecentVisit: nextVisits[0] || null,
        };
      }
      return state;
    },
  },
  setQRValidation: {
    action: setQRValidation,
    reducer: (state, validationUUID) => ({
      ...state,
      qrValidation: validationUUID,
    }),
  },
  setCurrentVisitUserSession: {
    action: setCurrentVisitUserSession,
    reducer: (state, { validation, rate }) => ({
      ...state,
      currentVisitUserSession: { validation, rate },
    }),
  },

  customerSetTip: {
    action: customerSetTip,
    done: (state, { result: { success, data } }) => {
      if (success) {
        const nextVisits = [...state.visits];
        const { transaction } = data;

        const visitIndex = nextVisits.findIndex((visit) => visit.id === transaction.id);
        if (visitIndex > -1) {
          nextVisits[visitIndex] = {
            ...nextVisits[visitIndex],
            // Update curr visit object
            ...transaction,
          };
        }
        return {
          ...state,
          visits: nextVisits,
          mostRecentVisit: nextVisits[0] || null,
        };
      }
      return state;
    },
  },
  fetchTipSettings: {
    action: fetchTipSettings,
    done: (state, { result: { success, data } }) => ({
      ...state,
      tippingEnabled: success ? data.tippingEnabled : null,
      suggestedTipAmounts: success ? data.suggestedTipAmounts : [],
    }),
  },
  resetCustomerVisitStore: {
    action: resetCustomerVisitStore,
    skipAsyncStore: true,
  },
  userConfirmedVisit: {
    action: userConfirmedVisit,
    reducer: (state, userHadToConfirmVisit) => ({
      ...state,
      userHadToConfirmVisit,
    }),
  },
  setDoorOpenRequest: {
    action: setDoorOpenRequest,
    reducer: (state, doorOpenRequest) => ({
      ...state,
      doorOpenRequest,
    }),
  },
  setUserPendingValidation: {
    action: setUserPendingValidation,
    reducer: (state, userPendingValidation) => ({
      ...state,
      userPendingValidation,
    }),
  },
};

const asyncResetReducers = [logout.done, resetCustomerVisitStore];
store.reset(logout.done);

export default applyReducers({ store, reducers, asyncResetReducers });
