import { apiFetch } from 'utils/http';

const VisitService = {
  /** fetch visit details using visit short code from sms */
  fetchVisitDetails: ({ shortCode, uuid }: { shortCode?: string; uuid?: string }) =>
    apiFetch('/customer/visit', { params: shortCode ? { shortCode } : { uuid } }),

  fetchVisitHistory: () => apiFetch('/customer/visits/history'),

  requestManualExit: (visitId: number) =>
    apiFetch(`/customer/visit/${visitId}/request-manual-exit`, { method: 'POST' }),
};

export const checkIsFirstTimeParker = async () => {
  const res = await VisitService.fetchVisitHistory();

  if (res.success && res.data) {
    if (Array.isArray(res.data?.visits) && res.data.visits.length < 1) {
      return true;
    }
    return false;
  }
  return false;
};

export default VisitService;
