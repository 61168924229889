import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import theme, { WebFonts } from '@metropolis-io/theme';

import { setDefaultAuthMethod } from 'utils/http';

import Customer from './Customer';
import { CustomerGlobalStyle } from './CustomerApp.styled';

import { CONNECT_TO_EXTERNAL_API } from 'constants/FeatureFlags';

if (CONNECT_TO_EXTERNAL_API) setDefaultAuthMethod('token');

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CustomerGlobalStyle />
      <WebFonts />
      <BrowserRouter>
        <Customer />
      </BrowserRouter>
    </ThemeProvider>
  );
}
