import { useState } from 'react';

import { useUnit } from 'effector-react';
import { Link } from 'react-router-dom';

import { ArrowLeft, ExternalWindow } from '@metropolis-io/icons';

import BottomSheet from 'apps/customer/components/BottomSheet';
import UserService from 'apps/customer/services/UserService';
import { userStore } from 'apps/customer/state/user';

import { showAlert } from 'state/alert';

import {
  Box,
  Header,
  PageLabel,
  ContentBox,
  ExternalLink,
  DeleteButton,
  DeleteSection,
  BsContainer,
  BsHeader,
  BsText,
  BsControls,
  BsCancelButton,
  BsDeleteButton,
} from './AccountDeletePage.styled';

export default function AccountDeletePage() {
  const { user } = useUnit(userStore);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  const deleteAccount = () => {
    if (user.id) {
      UserService.deleteAccount(user.id).then((res) => {
        if (res.success) {
          window.location.reload();
        } else {
          showAlert({
            label: 'There was an error deleting your account.',
            type: 'error',
          });

          setDeleteInProgress(false);
        }
      });
    }
  };

  const handleDeleteClick = () => {
    setDeleteInProgress(true);
    deleteAccount();
  };

  return (
    <Box>
      <ContentBox>
        <Header>
          <Link to="/profile">
            <ArrowLeft />
          </Link>
        </Header>
        <PageLabel>Terms and Privacy</PageLabel>
        <ExternalLink href="https://www.metropolis.io/terms" target="_blank">
          Terms of Service <ExternalWindow />
        </ExternalLink>
        <ExternalLink href="https://www.metropolis.io/privacy" target="_blank">
          Privacy Policy <ExternalWindow />
        </ExternalLink>
        <ExternalLink
          href="https://www.metropolis.io/privacy/#california-privacy-notice"
          target="_blank"
        >
          California Privacy Notice <ExternalWindow />
        </ExternalLink>
        <ExternalLink href="https://www.metropolis.io/do-not-sell" target="_blank">
          Do not Sell <ExternalWindow />
        </ExternalLink>
      </ContentBox>
      <DeleteSection>
        <DeleteButton onClick={() => setShowDeleteConfirmation(true)}>Delete account</DeleteButton>
      </DeleteSection>
      <BottomSheet open={showDeleteConfirmation} header={null}>
        <BsContainer>
          <BsHeader>Are you sure you want to delete your account?</BsHeader>
          <BsText>
            If you delete your account, you will not be able to pay for parking at
            Metropolis-powered locations.
          </BsText>
          <BsText>
            This will also result in you losing access to all history and payment methods associated
            with your account.
          </BsText>
          <BsControls>
            <BsCancelButton
              disabled={deleteInProgress}
              deleting={deleteInProgress}
              onClick={() => setShowDeleteConfirmation(false)}
            >
              Cancel
            </BsCancelButton>
            <BsDeleteButton
              disabled={deleteInProgress}
              deleting={deleteInProgress}
              onClick={handleDeleteClick}
            >
              {deleteInProgress ? 'Deleting...' : 'Delete'}
            </BsDeleteButton>
          </BsControls>
        </BsContainer>
      </BottomSheet>
    </Box>
  );
}
