import * as Sentry from '@sentry/react';

import getEnvironment from 'utils/getEnvironment';

import type { AppName } from 'types';

import { isProd } from 'constants/FeatureFlags';

export const dsnMap: { [env: string]: string } = {
  customer:
    'https://02579a22e42c4f19ad4818c0fa5bfb63@o4504596426719232.ingest.sentry.io/4504605362552832',
};

function getDsn(appName: AppName) {
  const dsn = dsnMap[appName];

  if (!dsn) {
    throw new Error(`initSentry Error: Unsupported app: ${appName}.`);
  }
  return dsn;
}

export default function initSentry(appName: AppName) {
  try {
    if (isProd) {
      const dsn = getDsn(appName);
      const environment = getEnvironment();

      Sentry.init({
        dsn,
        environment,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
            networkDetailAllowUrls: [window.location.origin, 'site.metropolis.io'],
          }),
        ],
        tracesSampleRate: 0.1,
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 0.1,
      });
    }
  } catch (e) {
    if (e instanceof Error) {
      // eslint-disable-next-line
      console.error(e);
    }
  }
}
