import { createStore } from 'effector';

import { applyReducers } from '@metropolis-io/effector-utils';
import type { DoneHandler } from '@metropolis-io/effector-utils';

import { logout } from 'apps/customer/state/user';

import { completeTask, fetchTodoItems, setTodoItems } from './actions';

import type { Effect, Event } from 'effector';
import type { APIResponse } from 'utils/http';

import {
  ADD_PAYMENT_TODO,
  ADD_VEHICLE_TODO,
  CONFIRM_VISIT,
  PAYMENT_OVERDUE_TODO,
  SELECT_VISIT_DURATION_TODO,
  ADD_PHONE_NUMBER_TODO,
  CONFIRM_MISSED_ENTRY_START_VISIT_TODO,
} from 'constants/TodoTypes';

import type {
  AddPaymentFollowup,
  AddVehicleFollowup,
  ConfirmVisitFollowup,
  SelectVisitDurationFollowUp,
  TodoItem,
  TodoItemType,
  AddPhoneNumberFollowup,
  MissedEntryStartVisitFollowUp,
  SelectVehicleFollowUp,
} from 'types/api';

export type OnboardingTasks = {
  list: TodoItem[];
  addVehicle?: AddVehicleFollowup;
  addPayment?: AddPaymentFollowup;
  selectVisitDuration?: SelectVisitDurationFollowUp;
  confirmVisit?: ConfirmVisitFollowup; // only available in v2
  addPhoneNumber?: AddPhoneNumberFollowup;
  selectVehicle?: SelectVehicleFollowUp;
  missedEntryStartVisit?: MissedEntryStartVisitFollowUp;
};

export type TodoState = {
  todoItems: TodoItem[] | null;
  onboardingTasks: OnboardingTasks | null;
  currentTaskType: TodoItemType | null;
  hasPendingTasks: boolean | null;
  hasPaymentTask: boolean | null;
  hasRequiredTasks: boolean | null;
  hasFetchedTodo: boolean;
};

const initialState: TodoState = {
  todoItems: null,
  onboardingTasks: null,
  currentTaskType: null,
  hasPendingTasks: null,
  hasPaymentTask: null,
  hasRequiredTasks: null,
  hasFetchedTodo: false,
};

const store = createStore(initialState);

type Reducers = {
  fetchTodoItems: {
    action: Effect<Parameters<typeof fetchTodoItems>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof fetchTodoItems>[0], TodoState>;
  };
  completeTask: {
    action: Event<TodoItemType>;
    reducer: (state: TodoState, payload: TodoItemType) => TodoState;
  };
  setTodoItems: {
    action: Event<{ todoItems: TodoItem[] }>;
    reducer: (state: TodoState, payload: { todoItems: TodoItem[] }) => TodoState;
  };
};

export function setOnboardingTasks(todoItems: TodoItem[]): OnboardingTasks {
  const onboardingTasks: Omit<OnboardingTasks, 'list'> = todoItems.reduce((curr, todo) => {
    if (todo.type === ADD_PHONE_NUMBER_TODO) {
      return {
        ...curr,
        addPhoneNumber: todo,
      };
    }
    if (todo.type === ADD_VEHICLE_TODO) {
      return {
        ...curr,
        addVehicle: todo,
      };
    }
    if (todo.type === ADD_PAYMENT_TODO) {
      return {
        ...curr,
        addPayment: todo,
      };
    }
    if (todo.type === SELECT_VISIT_DURATION_TODO) {
      return {
        ...curr,
        selectVisitDuration: todo,
      };
    }
    if (todo.type === CONFIRM_VISIT) {
      return {
        ...curr,
        confirmVisit: todo,
      };
    }
    if (todo.type === CONFIRM_MISSED_ENTRY_START_VISIT_TODO) {
      return {
        ...curr,
        missedEntryStartVisit: todo,
      };
    }
    return {
      ...curr,
    };
  }, {});

  return {
    ...onboardingTasks,
    list: todoItems,
  };
}

export const getFormatTodoItemData = (todoItems: TodoItem[]) => ({
  todoItems,
  onboardingTasks: setOnboardingTasks(todoItems),
  currentTaskType: todoItems && todoItems[0] ? todoItems[0].type : null,
  hasPendingTasks: !!todoItems?.length,
  hasPaymentTask: !!todoItems.find((todoItem) => todoItem.type === PAYMENT_OVERDUE_TODO),
  hasRequiredTasks: !!todoItems.find((todoItem) => todoItem.required),
  hasFetchedTodo: true,
});

export const reducers: Reducers = {
  fetchTodoItems: {
    action: fetchTodoItems,
    done: (state, { result: { success, data } }) => {
      if (success) {
        return {
          ...state,
          ...getFormatTodoItemData(data.todoItems),
        };
      }
      return state;
    },
  },
  completeTask: {
    action: completeTask,
    reducer: (state, type) => {
      let nextTodoItems: TodoItem[] = [];
      if (state.todoItems) {
        nextTodoItems = state.todoItems.filter((todoItem) => todoItem.type !== type);
      }

      return {
        ...state,
        ...getFormatTodoItemData(nextTodoItems),
      };
    },
  },
  setTodoItems: {
    action: setTodoItems,
    reducer: (state, { todoItems }) => ({
      ...state,
      ...getFormatTodoItemData(todoItems),
    }),
  },
};

const asyncResetReducers = [logout.done];

export default applyReducers({ store, reducers, asyncResetReducers });
