import { createStore, Effect } from 'effector';

import { applyReducers } from '@metropolis-io/effector-utils';
import type { DoneHandler } from '@metropolis-io/effector-utils';

import {
  addStripeCard,
  deletePaymentMethod,
  fetchPaymentMethods,
  payForOverdueVisits,
  setDefaultPaymentMethod,
} from './actions';

import type { APIResponse } from 'utils/http';

import { PaymentMethod } from 'types/api';

const initialState = {
  defaultPayment: null,
  paymentMethods: [],
  userPaymentMap: {},
};

const store = createStore<PaymentMethodState>(initialState);

type PaymentMethodState = {
  defaultPayment: PaymentMethod | null;
  paymentMethods: PaymentMethod[];
  userPaymentMap: { [key: string]: PaymentMethod[] };
  paymentOverdueError?: string;
};

type PaymentMethodReducers = {
  fetchPaymentMethods: {
    action: Effect<Parameters<typeof fetchPaymentMethods>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof fetchPaymentMethods>[0], PaymentMethodState>;
  };
  addStripeCard: {
    action: Effect<Parameters<typeof addStripeCard>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof addStripeCard>[0], PaymentMethodState>;
  };
  setDefaultPaymentMethod: {
    action: Effect<Parameters<typeof setDefaultPaymentMethod>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof setDefaultPaymentMethod>[0], PaymentMethodState>;
  };
  deletePaymentMethod: {
    action: Effect<Parameters<typeof deletePaymentMethod>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof deletePaymentMethod>[0], PaymentMethodState>;
  };
  payForOverdueVisits: {
    action: Effect<Parameters<typeof payForOverdueVisits>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof payForOverdueVisits>[0], PaymentMethodState>;
  };
};

export function findDefaultPayment(paymentMethods: PaymentMethod[]) {
  return (
    paymentMethods.find((payment: PaymentMethod) => payment.isDefaultMethod) || paymentMethods[0]
  );
}

export const reducers: PaymentMethodReducers = {
  fetchPaymentMethods: {
    action: fetchPaymentMethods,
    done: (state, { result: { success, data } = {} }) => ({
      ...state,
      paymentMethods: success ? data.paymentMethods : [],
      defaultPayment:
        success && data.paymentMethods.length > 0 ? findDefaultPayment(data.paymentMethods) : null,
    }),
  },

  addStripeCard: {
    action: addStripeCard,
    done: (state, { result: { success, data } = {} }) => {
      if (success) {
        const { paymentMethod } = data;
        const nextState = { ...state };
        nextState.paymentMethods.push(paymentMethod);
        nextState.defaultPayment = paymentMethod;
        return nextState;
      }

      return state;
    },
  },

  setDefaultPaymentMethod: {
    action: setDefaultPaymentMethod,
    done: (state, { result: { success, data } = {} }) => ({
      ...state,
      defaultPayment: success ? data.paymentMethod : state.defaultPayment,
    }),
  },

  deletePaymentMethod: {
    action: deletePaymentMethod,
    done: (state, { result: { success, data } = {} }) => {
      if (success) {
        const { paymentMethods } = data;
        const defaultPayment = findDefaultPayment(paymentMethods) || null;
        return {
          ...state,
          paymentMethods,
          defaultPayment,
        };
      }
      return state;
    },
  },

  payForOverdueVisits: {
    action: payForOverdueVisits,
    done: (state, { result: { success, data } = {}, params }) => {
      if (success && params?.cardToken) {
        const { paymentMethod } = data;
        const nextState = { ...state };
        nextState.paymentMethods.push(paymentMethod);
        nextState.defaultPayment = paymentMethod;
        return nextState;
      }

      return state;
    },
  },
};

export default applyReducers({ store, reducers });
