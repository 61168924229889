import { createStore, Effect } from 'effector';

import { applyReducers } from '@metropolis-io/effector-utils';
import type { DoneHandler } from '@metropolis-io/effector-utils';

import { countJoinQRCode, handleQRCode, startVisitQRCode } from './actions';

import type { APIResponse } from 'utils/http';

import type { QRType } from 'constants/QRTypes';

type State = {
  qrCodeType?: QRType;
};

const initialState: State = {};

const store = createStore(initialState);

type Reducers = {
  handleQRCode: {
    action: Effect<Parameters<typeof handleQRCode>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof handleQRCode>[0], APIResponse>;
  };
  countJoinQRCode: {
    action: Effect<Parameters<typeof countJoinQRCode>[0], APIResponse>;
  };
  startVisitQRCode: {
    action: Effect<Parameters<typeof startVisitQRCode>[0], APIResponse>;
  };
};

const reducers: Reducers = {
  handleQRCode: {
    action: handleQRCode,
    done: (state, { result: { success, data } }) => {
      if (success) {
        return {
          ...state,
          qrCodeType: data.type,
        };
      }
      return state;
    },
  },
  countJoinQRCode: {
    action: countJoinQRCode,
  },
  startVisitQRCode: {
    action: startVisitQRCode,
  },
};

export default applyReducers({ store, reducers });
