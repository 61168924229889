/* eslint-disable max-len */
import { createGlobalStyle } from 'styled-components';

export const CustomerGlobalStyle = createGlobalStyle`
  :root {
    background-color: ${({ theme }) => theme.colors.white};
    color: var(--customer-black);
    box-sizing: border-box;
    font-family: 'Neue Montreal', system-ui, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Roboto", sans-serif;
  }
  html,
  body {
    font-family: 'Neue Montreal', system-ui, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Roboto", sans-serif !important;
    background-color: inherit;
    color: #fff;
    margin: 0;
    padding: 0;
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    line-height: 1.5 !important;

    & *,
    & *::before,
    & *::after {
      box-sizing: inherit;
      color: inherit;
      letter-spacing: inherit;
    }
  }

  body,
  #root {
    flex: 1;
    width: 100%;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  button {
    font-family: 'Neue Montreal', system-ui, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Roboto", sans-serif !important;
  }

  div[role="button"],
  a,
  button,
  label {
    -webkit-tap-highlight-color: transparent;
  }
  
  input {
    font-family: 'Neue Montreal', system-ui, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Roboto", sans-serif !important;
  }
`;
