import { useEffect, useState } from 'react';

import { useUnit } from 'effector-react';

import { fetchPaymentMethods, paymentStore } from 'apps/customer/state/payment';
import { todoStore } from 'apps/customer/state/todo';
import { userStore } from 'apps/customer/state/user';
import { fetchCustomerVisits } from 'apps/customer/state/visit';

import { fetchEnums, fetchLPStates } from 'state/enum';
import { fetchVehicles, vehicleStore } from 'state/vehicle';

/**
 * Fetches the initial user data like vehicle, payment methods
 *
 * @param userId
 * @return true if all the data has been fetched at least once
 */
export function useInitialDataForUser(userId?: number | null) {
  const [initLoadComplete, setInitLoadComplete] = useState(false);

  const { authenticated } = useUnit(userStore);
  const { hasFetchedTodo } = useUnit(todoStore);
  const {
    status: { fetchPaymentMethodsSuccess },
  } = useUnit(paymentStore);
  const {
    status: { fetchVehiclesSuccess },
  } = useUnit(vehicleStore);

  // TODO: PERFORMANCE - Can we fetch these asynchronously? also fetch after phone verification
  useEffect(() => {
    if (userId) {
      fetchCustomerVisits();
      fetchVehicles();
      if (userId) {
        fetchPaymentMethods({ userId });
      }
      fetchEnums({ isAdmin: false });
      fetchLPStates();
    }
  }, [userId]);

  /* Fetching initial state */
  useEffect(() => {
    // Are todos, user, and other user related data fetched
    if (
      hasFetchedTodo &&
      authenticated !== null &&
      fetchPaymentMethodsSuccess !== null &&
      fetchVehiclesSuccess !== null
    ) {
      setInitLoadComplete(true);
    }
  }, [hasFetchedTodo, authenticated, fetchPaymentMethodsSuccess, fetchVehiclesSuccess]);

  return initLoadComplete;
}
