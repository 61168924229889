import { trackUserLoggedIn } from 'apps/customer/utils/heapOnboardTracking';
import { apiFetch } from 'utils/http';

import { CONNECT_TO_EXTERNAL_API } from 'constants/FeatureFlags';

const UserService = {
  getUser: async () => {
    const response = await apiFetch('/user');

    if (response.success) {
      trackUserLoggedIn(!!response.data?.user?.isRegistered);
    } else {
      trackUserLoggedIn(false);
    }

    return response;
  },

  logout: () => apiFetch('/logout', { method: 'POST' }),

  getUserSession: () => apiFetch('/user/visit/state'),

  parseInvitationCode: ({ invitationCode }: { invitationCode?: string }) =>
    apiFetch('/user/invitation', {
      params: {
        invitationCode,
      },
    }),

  signUpWithInvitation: ({
    invitationCode,
    firstName,
    lastName,
    email,
    password,
  }: {
    invitationCode?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    password?: string;
  }) =>
    apiFetch('/user/invitation', {
      method: 'POST',
      params: { invitationCode },
      body: {
        firstName,
        lastName,
        email,
        password,
      },
    }),

  // No invitation code is required
  signUpRegister: ({
    firstName,
    lastName,
    email,
    password,
    phoneNumber,
    agreements,
  }: {
    firstName?: string;
    lastName?: string;
    email?: string;
    password?: string;
    phoneNumber?: string;
    agreements?: { type: { id: number }; id: number }[];
  }) =>
    apiFetch('/register', {
      method: 'POST',
      body: {
        provider: 'metropolis',
        firstName,
        lastName,
        email,
        password,
        phoneNumber,
        agreements,
      },
    }),

  /**
   * Registers an unregistered customer
   *
   * This route accepts latest agreements and updates the user registration status
   */
  registerCustomerAndAcceptTerms: ({
    provider,
  }: {
    provider: 'metropolis'; // only metropolis for now
  }) =>
    apiFetch('/v2/register', {
      method: 'POST',
      body: {
        provider,
      },
    }),

  // Sends verification code if user exists or doesn't exist
  requestCode: ({ phoneNumber }: { phoneNumber: string }) =>
    apiFetch('/user/verification/send', {
      method: 'POST',
      body: {
        phoneNumber,
      },
    }),

  verifyCode: ({
    phoneNumber,
    code: secret,
    beginSession, // Stores session in cookie, else receives session token
  }: {
    phoneNumber?: string | null;
    code: string;
    beginSession: boolean;
  }) => {
    const sessionMode = beginSession ? 'cookie' : undefined;
    return apiFetch('/user/verification', {
      params: {
        phoneNumber,
        secret,
        sessionMode: CONNECT_TO_EXTERNAL_API ? 'token' : sessionMode,
      },
    });
  },

  updateUserInfo: ({
    phoneNumber,
    email,
    firstName,
    lastName,
  }: {
    phoneNumber?: string | null;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
  }) =>
    apiFetch('/customer/update-info', {
      method: 'PUT',
      body: {
        phoneNumber,
        email,
        firstName,
        lastName,
      },
    }),

  getActiveSubscriptions: () => apiFetch('/customer/subscription'),

  deleteAccount: (userId: number) =>
    apiFetch(`/customer/${userId}`, {
      method: 'DELETE',
    }),

  requestUpdateVerificationCode: ({ phoneNumber }: { phoneNumber: string }) =>
    apiFetch('/user/verification/update-phone/send', {
      method: 'POST',
      body: {
        phoneNumber,
      },
    }),

  verifyUpdateCode: ({ phoneNumber, secret }: { phoneNumber: string | null; secret: string }) =>
    apiFetch('/user/verify-phone-update', {
      method: 'POST',
      body: {
        phoneNumber,
        secret,
      },
    }),
};

export default UserService;
