import { add } from 'date-fns';
import { createEffect, createEvent } from 'effector';
import lStorage from 'local-storage-fallback';

import fetchWorker from 'utils/fetchWorker';
import { getClosestMetropolisRegion, MetropolisRegion } from 'utils/regionCoordinates';

import type { APIResponse } from 'utils/http';

const GeoLocationApi = 'https://services.metropolis.io/where-am-i';

export const setClosestRegion = createEvent<string>();

export const getGeolocationIP = createEffect({
  handler: async () => {
    try {
      const response: {
        lat?: string;
        long?: string;
        city?: string;
        closestRegion?: MetropolisRegion;
      } = await fetchWorker(GeoLocationApi);
      if (!response.lat || !response.long) throw new Error('No lat or long');
      const { long: longitude, lat: latitude } = response;
      const expire = add(new Date(), { months: 1 }).getTime();
      const closestRegion = getClosestMetropolisRegion({
        longitude: Number(longitude),
        latitude: Number(latitude),
      });
      lStorage.setItem('mp-region', JSON.stringify({ region: closestRegion.name, expire }));

      response.closestRegion = closestRegion;
      const responseObject: APIResponse = {
        success: true,
        data: response,
      };
      return responseObject;
    } catch (e) {
      return {
        success: true,
        data: {
          closestRegion: getClosestMetropolisRegion(),
        },
      };
    }
  },
});
