import { heapAddEventProperties, heapAddUserProperties, heapTrackProperties } from 'utils/heap';

import { ParkingType } from 'constants/ParkingTypes';
import type { VisitType } from 'constants/VisitType';

/** Event properties - user & visit type */
export const trackUserType = (userType: 'new' | 'returning') => {
  heapAddEventProperties({ userType });
};

// self-park-missed-entry turns into missed entry later on
export const trackVisitType = (visitType: VisitType) => {
  heapAddEventProperties({ visitType });
};

export const trackParkingType = (parkingType: ParkingType) => {
  heapAddEventProperties({ parkingType });
};

export const trackTransactionId = (transactionId: number) => {
  heapAddEventProperties({ transactionId });
};

export const trackQRSiteId = (siteId: number) => {
  heapAddEventProperties({ siteId });
};

export const trackQRSiteName = (siteName: string) => {
  heapAddEventProperties({ siteName });
};

export const trackIsMissedEntryEnabled = (isMissedEntryEnabled: boolean) => {
  heapAddEventProperties({ isMissedEntryEnabled });
};

export const trackUserLoggedIn = (isLoggedIn: boolean) => {
  heapAddEventProperties({ isLoggedIn });
};

export const trackRateType = (rateType: 'durationRate' | 'flatRate' | 'tabularRate') => {
  heapAddEventProperties({ rateType });
};

/** Event properties - timestamps */
export const trackPhonePageTimestamp = () => {
  heapAddEventProperties({ phonePageTimestamp: Date.now() });
};

export const trackVerificationPageTimestamp = () => {
  heapAddEventProperties({ verificationPageTimestamp: Date.now() });
};

export const trackVehiclePageTimestamp = () => {
  heapAddEventProperties({ vehiclePageTimestamp: Date.now() });
};

export const trackPaymentPageTimestamp = () => {
  heapAddEventProperties({ paymentPageTimestamp: Date.now() });
};

export const trackPaymentAddedTimestamp = () => {
  heapAddEventProperties({ paymentAddedTimestamp: Date.now() });
};

export const trackLandingPageTimestamp = () => {
  heapAddEventProperties({ landingPageTimestamp: Date.now() });
};

export const trackVisitStartTimestamp = () => {
  heapAddEventProperties({ visitStartTimestamp: Date.now() });
};
export const trackIsFirstTimeUser = (isFirstTimeUser: boolean) => {
  heapAddEventProperties({ isFirstTimeUser });
};

export const trackDoesHaveSubcriptions = (data: {
  isMonthlySubscriber: boolean;
  isCompanySubscriber: boolean;
}) => {
  heapAddUserProperties(data);
};
/**
 * Custom tracking
 * errorMessage may contain license plate text
 */
export const trackFailedAddVehicle = (failureData: { [x: string]: string }) => {
  heapTrackProperties('addVehicleFailed', failureData);
};

export const trackVisitId = (visitId: number) => {
  heapTrackProperties('Visit Info', {
    visitId,
  });
};

export const trackCieFlowStarted = (data: { userFlow: string; isFallback: boolean }) => {
  const send = { ...data, isFallback: data.isFallback ? 'true' : 'false' };
  heapTrackProperties('CieFlowStarted', send);
};

export const trackCieFlowCompleted = (data: {
  userFlow: string;
  isFallback: boolean;
  transactionId: number | undefined;
}) => {
  const send = { ...data, isFallback: data.isFallback ? 'true' : 'false' };
  heapTrackProperties('CieFlowCompleted', send);
};

export const trackLpCorrectionFlow = (lpCorrectionData: { [x: string]: string }) => {
  heapTrackProperties('LPCorrectionTransaction', lpCorrectionData);
};

export const trackPastDueVisitViewed = (numItems: number) => {
  heapTrackProperties('PastDueVisitViewed', { numItems });
};

export const trackPastDueVisitPaid = (numItems: number) => {
  heapTrackProperties('PastDueVisitPaid', { numItems });
};
