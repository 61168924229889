import { createStore, Effect, Event } from 'effector';

import { applyReducers } from '@metropolis-io/effector-utils';
import type { DoneHandler } from '@metropolis-io/effector-utils';

import {
  getUser,
  logout,
  parseEnterpriseInviteToken,
  parseInvitationCode,
  registerCustomerAndAcceptTerms,
  requestCode,
  resetUserAsyncStatuses,
  setTokenAndUserInfoForEnterpriseInvitation,
  setUser,
  signUpRegister,
  updateUser,
  verifyCode,
} from './actions';

import type { APIResponse } from 'utils/http';

import type { User } from 'types/api';
import type { EnterpriseInvitationUser } from 'types/api/EnterpriseInvitation';

export type UserState = {
  // TODO: fix type to be 'User | null'
  user: User | { [key: string]: undefined };
  invitation:
    | {
        invitationCode: string | undefined;
        phoneNumber: string | undefined;
      }
    | { [key: string]: undefined };
  isInvitedUser?: boolean;
  signUpErrors: { key: string; reason: string }[] | null | undefined;
  phoneNumber: string | null;
  authenticated: boolean | null;
  enterpriseUser?: EnterpriseInvitationUser;
};

const initialState: UserState = {
  user: {},
  invitation: {},
  phoneNumber: null,
  signUpErrors: null,
  authenticated: null,
};

const store = createStore(initialState);

const updateUserState = (
  state: UserState,
  { result: { success, data } }: { result: APIResponse },
) => ({
  ...state,
  user: success ? data.user : {},
  authenticated: success && data.user.isRegistered,
});

type Reducers = {
  getUser: {
    action: Effect<Parameters<typeof getUser>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getUser>[0], UserState>;
  };
  parseInvitationCode: {
    action: Effect<Parameters<typeof parseInvitationCode>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof parseInvitationCode>[0], UserState>;
  };
  signUpRegister: {
    action: Effect<Parameters<typeof signUpRegister>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof signUpRegister>[0], UserState>;
  };
  registerCustomerAndAcceptTerms: {
    action: Effect<Parameters<typeof registerCustomerAndAcceptTerms>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof registerCustomerAndAcceptTerms>[0], UserState>;
  };
  requestCode: {
    action: Effect<Parameters<typeof requestCode>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof requestCode>[0], UserState>;
  };
  verifyCode: {
    action: Effect<Parameters<typeof verifyCode>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof verifyCode>[0], UserState>;
  };
  logout: {
    action: Effect<Parameters<typeof logout>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof logout>[0], UserState>;
    skipAsyncStore: boolean;
  };
  setUser: {
    action: Event<{ user: User | null }>;
    reducer: (state: UserState, payload: { user: User | null }) => UserState;
  };
  setTokenAndUserInfoForEnterpriseInvitation: {
    action: Event<{ enterpriseUser: EnterpriseInvitationUser }>;
    reducer: (state: UserState, payload: { enterpriseUser: EnterpriseInvitationUser }) => UserState;
  };
  updateUser: {
    action: Effect<Parameters<typeof updateUser>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof updateUser>[0], UserState>;
  };
  parseEnterpriseInviteToken: {
    action: Effect<
      { token: string },
      { user: User | null; enterpriseUser?: EnterpriseInvitationUser }
    >;
    done: (
      state: UserState,
      payload: { result: { user: User | null; enterpriseUser?: EnterpriseInvitationUser } },
    ) => UserState;
  };
};

export const reducers: Reducers = {
  getUser: {
    action: getUser,
    done: updateUserState,
  },
  parseInvitationCode: {
    action: parseInvitationCode,
    done: (state, { params: { invitationCode }, result: { success, data } }) => {
      if (success) {
        return {
          ...state,
          invitation: {
            invitationCode,
            isRegistered: data.user.isRegistered,
            phoneNumber: data.user.phoneNumber,
            licensePlateText: data.licensePlate ? data.licensePlate.text : null,
          },
          isInvitedUser: true,
          phoneNumber: data.user.phoneNumber,
          user: data.user,
          authenticated: data.user.isRegistered,
        };
      }
      return state;
    },
  },
  signUpRegister: {
    action: signUpRegister,
    done: (state, { result: { success, data, errors } }) => ({
      ...state,
      user: success ? data.user : {},
      authenticated: success && data.user.isRegistered,
      signUpErrors: success ? null : errors,
    }),
  },
  registerCustomerAndAcceptTerms: {
    action: registerCustomerAndAcceptTerms,
    done: (state, { result: { success, data, errors } }) => ({
      ...state,
      user: success ? data.user : state.user,
      authenticated: success ? data.user.isRegistered : state.authenticated,
    }),
  },
  requestCode: {
    action: requestCode,
    done: (state, { params: { phoneNumber } }) => ({
      ...state,
      phoneNumber,
    }),
  },
  verifyCode: {
    action: verifyCode,
    done: (state, { result: { success, data } }) => ({
      ...state,
      token: success ? data.token : null,
      user: success ? data.user : {},
      authenticated: success && data.user.isRegistered,
    }),
  },
  logout: {
    action: logout,
    done: () => ({
      // Not using store.reset so we can set authenticated to false
      ...initialState,
      authenticated: false,
    }),
    skipAsyncStore: true,
  },
  setUser: {
    action: setUser,
    reducer: (state, { user }) => ({
      ...state,
      user: user || {},
      authenticated: !!user && user.isRegistered,
    }),
  },
  setTokenAndUserInfoForEnterpriseInvitation: {
    action: setTokenAndUserInfoForEnterpriseInvitation,
    reducer: (state, { enterpriseUser }) => ({
      ...state,
      enterpriseUser,
    }),
  },
  updateUser: {
    action: updateUser,
    done: (state, { result: { success, data, errors } }) => ({
      ...state,
      user: {
        ...state.user,
        ...(success ? data : {}),
      },
    }),
  },
  parseEnterpriseInviteToken: {
    action: parseEnterpriseInviteToken,
    done: (state, { result: { user, enterpriseUser } }) => ({
      ...state,
      user: user || {},
      authenticated: !!user && user.isRegistered,
      enterpriseUser,
    }),
  },
};

// Not resetting store so we can set authenticated to false
const asyncResetReducers = [logout.done, resetUserAsyncStatuses];

export default applyReducers({ store, reducers, asyncResetReducers });
