/* eslint-disable max-len */
import { useState, useEffect, useRef } from 'react';

import { useUnit } from 'effector-react';
import isEqual from 'lodash/isEqual';

import VisitService from '../services/VisitService';
import { userStore } from '../state/user';
import { setCustomerVisits, updatedVisitHistory } from '../state/visit';
import { trackIsFirstTimeUser } from '../utils/heapOnboardTracking';

import type { UserVisit, Visit } from 'types/api';

// returns false if user has started a new visit
function userHasNotStartedSecondVisit(visits: Visit[], visitHistory: UserVisit[]) {
  if (visitHistory.length > 1) return false;
  if (!visits.length || !visitHistory.length) return true;
  return visits[0].uuid === visitHistory[0].uuid;
}

const useIsFirstTimeUser = () => {
  const [visitHistory, setVisitHistory] = useState<UserVisit[] | null>();
  const [visits, setVisits] = useState<Visit[] | null>();
  const [reported, setReported] = useState<boolean>(false);
  const refVisitHistoryLength = useRef<number>(0);
  const refvisits = useRef<Visit[]>();
  const {
    user: { phoneNumber },
  } = useUnit(userStore);

  /* listen to update visit history event to updated visit history*/
  useEffect(() => {
    const removeVisitHistoryListener = updatedVisitHistory.watch((data) => {
      setVisitHistory(data);
    });
    return () => {
      removeVisitHistoryListener();
    };
  }, []);

  /* listen to update visit event to updated visit history*/
  useEffect(() => {
    const removeVisitListener = setCustomerVisits.watch((data) => {
      setVisits(data);
    });
    return () => {
      removeVisitListener();
    };
  }, []);

  useEffect(() => {
    // get visit history once we have a phone number. Skip if we have visit history
    if (phoneNumber && !visitHistory) {
      VisitService.fetchVisitHistory().then(({ success, data }) => {
        if (success) {
          setVisitHistory(data.visits);
        }
      });
    }

    /* we check if the visit history length has changed or not to report again*/
    if (
      visitHistory?.length !== refVisitHistoryLength.current ||
      !isEqual(visits, refvisits.current)
    ) {
      setReported(false);
    }

    /* if not reported check if user is authenticated by having a phone number, has visit history details and current visist details.
    User is considered first time user if they have not started a second visit.
    If visit history has 2 entries then user has completed two visists
    If user history has 1 and current visits has 1 we must verify it isnt the same visit (because the api returns last visit if there are no current visist.)
    */
    if (!reported) {
      if (phoneNumber && visits && visitHistory) {
        // track visit history length to know if we should report again after user completes a visit
        refVisitHistoryLength.current = visitHistory.length;
        refvisits.current = visits;
        // if zero visits report
        if (!visitHistory.length) {
          trackIsFirstTimeUser(true);
        } else if (userHasNotStartedSecondVisit(visits, visitHistory)) {
          trackIsFirstTimeUser(true);
        } else {
          trackIsFirstTimeUser(false);
        }
        setReported(true);
      }
    }
  }, [phoneNumber, visitHistory, reported, visits]);
};

export default useIsFirstTimeUser;
