import { useEffect, useCallback, useState } from 'react';

import { useUnit } from 'effector-react';

import { alertStore, hideAlert } from 'state/alert';

import { AlertContainer } from './AppAlert.styled';

import type { AlertContainerProps } from './AppAlert.styled';

export default function AppAlert(props: AlertContainerProps) {
  const [hideTimer, setHideTimer] = useState<number>();
  const { alertVisible, label, type, duration = 6000, css, onClick } = useUnit(alertStore);

  // Clear the previous timer if another timer is set
  useEffect(() => () => clearTimeout(hideTimer), [hideTimer]);

  useEffect(() => {
    if (alertVisible) {
      const timer = window.setTimeout(() => {
        hideAlert();
      }, duration);
      setHideTimer(timer);
    }
  }, [alertVisible, label, duration, type]);

  const handleDismiss = useCallback(() => {
    if (onClick) return;

    hideAlert();
    clearTimeout(hideTimer);
  }, [onClick, hideTimer]);

  return (
    <AlertContainer
      {...props}
      tabIndex={0}
      onTouchStart={handleDismiss}
      onMouseDown={handleDismiss}
      onKeyPress={handleDismiss}
      visible={alertVisible}
      error={type === 'error'}
      warning={type === 'warning'}
      success={type === 'success'}
      css={css}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      {label}
    </AlertContainer>
  );
}
