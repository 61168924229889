/**
 * subscriptionId:
 *  1. is like a shopping card id
 *  2. we get the subscriptionId back after POST a planId to /checkout endpoint
 *  3. there is no endpoint to remove an item from shopping cart,
 *     so when user removes an item, we just need to get rid of current subId and start over
 */

import { createStore, Effect, Event } from 'effector';

import { applyReducers } from '@metropolis-io/effector-utils';
import type { DoneHandler } from '@metropolis-io/effector-utils';

import {
  addSubscriptionToCart,
  cancelSubscription,
  getActiveSubscriptions,
  getAvailableAndActiveSubscriptions,
  makePaymentForSubscription,
  removeSubscriptionFromCart,
  resetSubscriptionAsyncStatuses,
} from './actions';

import type { APIResponse } from 'utils/http';

import {
  ExistingSubscriptions,
  Site,
  Subscription,
  SubscriptionPlan,
  SubscriptionSite,
} from 'types/api';

export type SubscriptionState = {
  currentSubscriptionId: number | null;
  sites: Site[];
  selectedSite: Site | null;
  existingSubscriptions: Subscription[];
  upcomingSubscriptions: Subscription[];
  recommendedSites: Site[];
  subscriptionPlans: {
    [siteId: number]: SubscriptionPlan[];
  };
  sitesMap: {
    [siteId: number]: Site;
  };
  cart?: { subscription: Subscription };
};

const initialState: SubscriptionState = {
  currentSubscriptionId: null,
  sites: [],
  selectedSite: null,
  existingSubscriptions: [],
  upcomingSubscriptions: [],
  recommendedSites: [],
  subscriptionPlans: {},
  sitesMap: {},
};

const store = createStore(initialState);

type Reducers = {
  getAvailableAndActiveSubscriptions: {
    action: Effect<Parameters<typeof getAvailableAndActiveSubscriptions>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getAvailableAndActiveSubscriptions>[0], SubscriptionState>;
  };
  getActiveSubscriptions: {
    action: Effect<Parameters<typeof getActiveSubscriptions>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof getActiveSubscriptions>[0], SubscriptionState>;
  };
  addSubscriptionToCart: {
    action: Effect<Parameters<typeof addSubscriptionToCart>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof addSubscriptionToCart>[0], SubscriptionState>;
  };
  makePaymentForSubscription: {
    action: Effect<Parameters<typeof makePaymentForSubscription>[0], APIResponse>;
  };
  removeSubscriptionFromCart: {
    action: Event<Parameters<typeof removeSubscriptionFromCart>[0]>;
    reducer: (
      state: SubscriptionState,
      bounds: Parameters<typeof removeSubscriptionFromCart>[0],
    ) => SubscriptionState;
  };
  cancelSubscription: {
    action: Effect<Parameters<typeof cancelSubscription>[0], APIResponse>;
    done: DoneHandler<Parameters<typeof cancelSubscription>[0], SubscriptionState>;
  };
};

export const reducers: Reducers = {
  getAvailableAndActiveSubscriptions: {
    action: getAvailableAndActiveSubscriptions,
    done: (state, { result: { data } }) => {
      const subscriptionPlans: { [siteId: number]: SubscriptionPlan[] } = {};
      const sitesMap: { [siteId: number]: Site } = {};

      const { recommendedSites: recommendedSitesResp, sites: sitesResp } = data;

      const recommendedSites = recommendedSitesResp.map((recommended: SubscriptionSite) => {
        subscriptionPlans[recommended.site.id] = recommended.subscriptionPlans;
        sitesMap[recommended.site.id] = recommended.site;
        return recommended.site;
      });

      const sites = sitesResp.map((subscriptionSites: SubscriptionSite) => {
        subscriptionPlans[subscriptionSites.site.id] = subscriptionSites.subscriptionPlans;
        sitesMap[subscriptionSites.site.id] = subscriptionSites.site;
        return subscriptionSites.site;
      });

      return {
        ...state,
        recommendedSites,
        sites,
        subscriptionPlans,
        sitesMap,
      };
    },
  },
  getActiveSubscriptions: {
    action: getActiveSubscriptions,
    done: (state, { result: { data } }) => {
      const sitesMap: { [siteId: number]: Site } = {};

      const supscriptionSplit = data.reduce(
        // @ts-ignore
        (
          acc: { existing: ExistingSubscriptions[]; upcoming: ExistingSubscriptions[] },
          curr: ExistingSubscriptions,
        ) => {
          sitesMap[curr.site.id] = curr.site;

          if (Number(curr.subscription.details?.accessStart) < Date.now()) {
            acc.existing.push(curr);
            return acc;
          }
          if (curr.subscription.details?.accessEnd === curr.subscription.details?.accessStart) {
            return acc;
          }

          acc.upcoming.push(curr);
          return acc;
        },
        {
          existing: [],
          upcoming: [],
        },
      );
      // @ts-ignore
      const existingSubscriptions = supscriptionSplit.existing.map(
        (existingSubscription: ExistingSubscriptions) => {
          const nextExistingSubscription = { ...existingSubscription };
          nextExistingSubscription.subscription.enterpriseDetails =
            existingSubscription.enterpriseDetails;
          nextExistingSubscription.subscription.site = existingSubscription.site;
          // We are hardcoding the after hour label for the Ally Bank time based access subscription.
          // We'll need to eventually get this from the BE=
          if (
            nextExistingSubscription?.subscription?.id === 56817 &&
            nextExistingSubscription?.subscription?.details?.label === null
          ) {
            nextExistingSubscription.subscription.details.label = { AssignmentType: 'After Hours' };
          }
          nextExistingSubscription.subscription.site = existingSubscription.site;
          return nextExistingSubscription.subscription;
        },
      );

      // @ts-ignore
      const upcomingSubscriptions = supscriptionSplit.upcoming.map(
        (existingSubscription: ExistingSubscriptions) => {
          const nextExistingSubscription = { ...existingSubscription };
          nextExistingSubscription.subscription.enterpriseDetails =
            existingSubscription.enterpriseDetails;
          nextExistingSubscription.subscription.site = existingSubscription.site;
          return nextExistingSubscription.subscription;
        },
      );

      return {
        ...state,
        sitesMap: { ...state.sitesMap, ...sitesMap },
        existingSubscriptions,
        upcomingSubscriptions,
      };
    },
  },
  addSubscriptionToCart: {
    action: addSubscriptionToCart,
    done: (state, { result: { success, data } = {} }) => ({
      ...state,
      currentSubscriptionId: success ? data.subscription.id : null,
      cart: data,
    }),
  },
  makePaymentForSubscription: {
    action: makePaymentForSubscription,
  },
  removeSubscriptionFromCart: {
    action: removeSubscriptionFromCart,
    reducer: (state) => ({
      ...state,
      currentSubscriptionId: null,
    }),
  },
  cancelSubscription: {
    action: cancelSubscription,
    done: (state, { result: { success, data } }) => {
      if (success) {
        const nextExistingSubs = [...state.existingSubscriptions];
        const canceledSubIndex = nextExistingSubs.findIndex(
          (sub) => sub.id === data.subscriptionId,
        );
        nextExistingSubs.splice(canceledSubIndex, 1);

        return {
          ...state,
          existingSubscriptions: nextExistingSubs,
        };
      }
      return state;
    },
  },
};

const asyncResetReducers = [resetSubscriptionAsyncStatuses];

export default applyReducers({ store, asyncResetReducers, reducers });
