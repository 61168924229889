import * as Sentry from '@sentry/react';
import { render } from 'react-dom';

import CustomerApp from 'apps/customer';
import { setCurrentAppName } from 'utils/currentApp';
import { initDatadog } from 'utils/datadog';
import { setTokenName } from 'utils/http';
import initSentry from 'utils/initSentry';

setCurrentAppName('customer');
setTokenName('customer');
initDatadog('customer');
initSentry('customer');

if (!navigator.cookieEnabled) {
  document.getElementById('initial-loader')?.remove();
  render(
    <div style={{ fontFamily: 'sans-serif', fontSize: 20, fontWeight: 'bold' }}>
      Please turn on cookies to use Metropolis.
    </div>,
    document.getElementById('root'),
  );
} else {
  render(
    <Sentry.ErrorBoundary>
      <CustomerApp />
    </Sentry.ErrorBoundary>,
    document.getElementById('root'),
  );
}
