import { apiFetch } from 'utils/http';

export const EnterpriseInvitationService = {
  parseInviteToken: ({ token }: { token: string }) => apiFetch(`/enterprise/invitation/${token}`),

  redeemInvite: ({ token }: { token: string }) =>
    apiFetch(`/enterprise/invitation/${token}/redeem`, { method: 'POST' }),

  // this is used for bedrock we send a token as well as a employeeUuid
  claimRedemptionToken: ({ token, employeeId }: { token: string; employeeId: string }) =>
    apiFetch(`/customer/account/${token}/redeem`, {
      method: 'POST',
      body: {
        employeeId,
      },
    }),
};
