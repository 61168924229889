import { datadogRum } from '@datadog/browser-rum';

import { AppName } from 'types';

import { ENABLE_RUM, ENABLE_RUM_CUSTOMER_ONLY } from 'constants/FeatureFlags';

const DD_APP_ID = process.env.REACT_APP_DD_APP_ID;
const DD_CLIENT_TOKEN = process.env.REACT_APP_DD_CLIENT_TOKEN;

export function initDatadog(app: AppName) {
  if (
    ENABLE_RUM &&
    (!ENABLE_RUM_CUSTOMER_ONLY || app === 'customer') &&
    DD_APP_ID &&
    DD_CLIENT_TOKEN
  ) {
    datadogRum.init({
      applicationId: DD_APP_ID,
      clientToken: DD_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: `${app}-app`,
      env: process.env.REACT_APP_CONFIG_ENV,

      version: process.env.REACT_APP_VERSION || undefined,
      sessionSampleRate: 1,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
  }
}
