import { createEffect, createEvent } from 'effector';

import { EnterpriseInvitationService } from 'apps/customer/services/EnterpriseInvitationService';
import UserService from 'apps/customer/services/UserService';
import {
  trackIsFirstTimeUser,
  trackUserLoggedIn,
  trackUserType,
} from 'apps/customer/utils/heapOnboardTracking';
import cleanUri from 'utils/cleanUri';
import { heapClearEventProperties, heapIdentityReset } from 'utils/heap';
import { setAuthToken } from 'utils/http';

import { showAlert } from 'state/alert';

import { CONNECT_TO_EXTERNAL_API } from 'constants/FeatureFlags';

import type { User } from 'types/api';
import type { EnterpriseInvitationUser } from 'types/api/EnterpriseInvitation';

export const getUser = createEffect({
  handler: () => UserService.getUser(),
});

export const logout = createEffect({
  handler: async () => {
    heapIdentityReset();
    heapClearEventProperties();

    const response = await UserService.logout();

    if (response.success) {
      trackUserLoggedIn(false);
    }

    return response;
  },
});

export const parseInvitationCode = createEffect({
  handler: ({ invitationCode }: { invitationCode?: string }) =>
    UserService.parseInvitationCode({ invitationCode }),
});

// No invitation code is required
export const signUpRegister = createEffect({
  handler: async ({
    firstName,
    lastName,
    email,
    password,
    phoneNumber,
    agreements,
  }: {
    firstName?: string;
    lastName?: string;
    email?: string;
    password?: string;
    phoneNumber?: string;
    agreements?: { type: { id: number }; id: number }[];
  }) => {
    const response = await UserService.signUpRegister({
      firstName,
      lastName,
      email,
      password,
      phoneNumber,
      agreements,
    });

    if (response.success) {
      trackUserLoggedIn(true);
    }
    return response;
  },
});

export const registerCustomerAndAcceptTerms = createEffect({
  handler: async () => {
    const response = await UserService.registerCustomerAndAcceptTerms({
      provider: 'metropolis',
    });
    if (response.success) {
      trackUserLoggedIn(true);
    }
    return response;
  },
});

export const updateUser = createEffect({
  handler: async (data: {
    phoneNumber?: string | null;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
  }) => {
    const response = await UserService.updateUserInfo(data);
    return response;
  },
});

// Sends verification code if user exists or doesn't exist
export const requestCode = createEffect({
  handler: async ({ phoneNumber }: { phoneNumber: string }) =>
    UserService.requestCode({ phoneNumber }),
});

export const verifyCode = createEffect({
  handler: async ({
    phoneNumber,
    code,
    beginSession, // Stores session in cookie, else receives session token
  }: {
    phoneNumber?: string | null;
    code: string;
    beginSession: boolean;
  }) => {
    const response = await UserService.verifyCode({
      phoneNumber,
      code,
      beginSession,
    });

    if (response.success) {
      const { isRegistered } = response.data.user;
      trackUserLoggedIn(isRegistered);
      trackUserType(isRegistered ? 'returning' : 'new');
      if (!isRegistered) trackIsFirstTimeUser(true);
      CONNECT_TO_EXTERNAL_API && setAuthToken(response?.data?.token);
    }

    return response;
  },
});

export const parseEnterpriseInviteToken = createEffect({
  handler: async ({ token }: { token: string }) => {
    const invitePromise = await EnterpriseInvitationService.parseInviteToken({ token });
    const userPromise = UserService.getUser();
    const [inviteResp, userResp] = await Promise.all([invitePromise, userPromise]);

    const user = userResp.success ? userResp.data.user : null;

    if (inviteResp.success) {
      return { user, enterpriseUser: inviteResp.data };
    }
    showAlert({ label: inviteResp.error || 'Cannot redeem invitation.', type: 'error' });
    cleanUri();
    return { user };
  },
});

export const setUser = createEvent<{ user: User | null }>();

/**
 * user - current user on session
 * enterpriseUser - returns the invited enterprise user info that was filled by the enterprise admin
 */
export const setEnterpriseInvitationUser = createEvent<{
  user: User | null;
  enterpriseUser: EnterpriseInvitationUser;
}>();

export const resetUserAsyncStatuses = createEvent();

/**
 * token - token sent with phone number as first step in loggingin
 * enterpriseUser - returns the invited enterprise user info that was filled by the enterprise admin
 */
export const setTokenAndUserInfoForEnterpriseInvitation = createEvent<{
  enterpriseUser: EnterpriseInvitationUser;
}>();
